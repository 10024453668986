import * as React from "react";
import { perPageList, sortByList, useClients } from "./useClients";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  PlusIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import { ClientTable } from "./components/clientTable/clientTable";
import classNames from "classnames";
import { titleize } from "app/utils/string";
import { Drawer } from "app/components/drawer/drawer";
import { AddClient } from "./components/drawer/addClient/addClient";
import { UpdateClient } from "./components/drawer/updateClient/updateClient";
import { Loading } from "app/components/loading/loading";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { AsButton, AsIconButton } from "app/subframe";
import { EmptyState } from "app/components/emptyState";
import { BlackListDrawer } from "./components/blacklistDrawer/blacklistDrawer";

export interface ClientsProps {}

export const Clients: React.FC<ClientsProps> = ({}) => {
  const {
    clients,
    rowsPerPage,
    setRowsPerPage,
    sortBy,
    setSortBy,
    shouldShowClientDrawer,
    setShouldShowClientDrawer,
    onClientRowClick,
    clientIdParam,
    onOpenNewClientDrawer,
    loading,
    totalCount,
    shouldShowBlacklistDrawer,
    setShouldShowBlacklistDrawer,
    onBlacklistAdd,
    onBlacklistRemove,
    account,
  } = useClients();

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <div
      className={classNames("flex flex-col p-4 gap-4 mobile:p-2 mobile:gap-2", {
        "h-[85dvh]": isBelowSm && shouldShowClientDrawer,
      })}
    >
      {isBelowSm && (
        <div className="flex items-center gap-2">
          <span className="text-[20px] font-[500] leading-[30px] text-neutral-700">
            Clients
          </span>
          <span className="grow shrink-0 basis-0 text-[14px] font-[400] leading-[20px] text-neutral-700">
            {totalCount} clients
          </span>
        </div>
      )}
      <BlackListDrawer
        shouldShowBlacklistDrawer={shouldShowBlacklistDrawer}
        setShouldShowBlacklistDrawer={setShouldShowBlacklistDrawer}
        account={account}
        onBlacklistAdd={onBlacklistAdd}
        onBlacklistRemove={onBlacklistRemove}
      />
      <Drawer
        isOpen={shouldShowClientDrawer}
        setIsOpen={setShouldShowClientDrawer}
        backdrop={false}
      >
        {clientIdParam ? (
          <UpdateClient
            clientId={Number(clientIdParam)}
            setCloseParent={setShouldShowClientDrawer}
          />
        ) : (
          <AddClient
            setCloseParent={setShouldShowClientDrawer}
            key={Date.now()}
          />
        )}
      </Drawer>
      <div className="flex items-center justify-between mobile:pb-2">
        {isAboveSm && (
          <div className="flex gap-4 items-center">
            <AsButton
              variant="neutral-secondary"
              size="md"
              onClick={() => onOpenNewClientDrawer()}
            >
              <PlusIcon className="w-4 h-4 stroke-black" />
              <p className="font-semibold ">Add Client</p>
            </AsButton>
            <p className="text-zinc-500">{clients.length} clients</p>
          </div>
        )}
        <div className={"flex justify-between mobile:w-full"}>
          {isBelowSm && (
            <AsIconButton
              variant="ghost"
              leadingIcon={"FeatherUserX"}
              size="sm"
              disabled={false}
              disabledSecondary={false}
              onClick={() => setShouldShowBlacklistDrawer(true)}
            />
          )}
          <div className="flex gap-4">
            {isAboveSm && (
              <AsButton
                variant="ghost"
                leadingIcon={"FeatherUserX"}
                text="Blacklist"
                onClick={() => setShouldShowBlacklistDrawer(true)}
                size="sm"
                loader={false}
                disabled={false}
                disabledSecondary={false}
              />
            )}
            <Dropdown>
              <DropdownTrigger>
                <AsButton
                  variant="ghost"
                  trailingIcon="FeatherChevronDown"
                  text="Sort"
                  size="sm"
                  loader={false}
                  disabled={false}
                  disabledSecondary={false}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {sortByList.map((sb) => (
                  <DropdownItem
                    key={sb.value}
                    onClick={() => setSortBy(sb)}
                    endContent={
                      <CheckIcon
                        className={classNames("w-3 h-3 stroke-black", {
                          hidden: sb !== sortBy,
                        })}
                      />
                    }
                  >
                    {titleize(sb.title)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <AsButton
                  variant="ghost"
                  leadingIcon={null}
                  trailingIcon="FeatherChevronDown"
                  className="text-neutral-900"
                  text={`${rowsPerPage} per page`}
                  size="sm"
                  loader={false}
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {perPageList.map((perPage) => (
                  <DropdownItem
                    key={perPage}
                    onClick={() => setRowsPerPage(perPage)}
                  >
                    {perPage} per page
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      {clients.length === 0 ? (
        <div className="w-full h-[calc(100dvh-200px)] flex justify-center items-center flex-col gap-10">
          <EmptyState />
          <AsButton
            text={"Add a new client"}
            onClick={() => onOpenNewClientDrawer()}
          />
        </div>
      ) : (
        <ClientTable
          rowsPerPage={rowsPerPage}
          onClientRowClick={onClientRowClick}
        />
      )}
      {isBelowSm && (
        <AsButton
          variant="brand"
          leadingIcon="FeatherPlus"
          trailingIcon={null}
          text="Add client"
          size="md"
          loader={false}
          shadow
          onClick={() => onOpenNewClientDrawer()}
          className="fixed bottom-24 self-center z-50"
        />
      )}
    </div>
  );
};
