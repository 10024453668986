import * as React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { addDays, subDays, subHours } from "date-fns";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { CrudAppointmentActions, useSchedules } from "./useSchedules";
import { CustomToolbar } from "./components/customToolbar/customToolbar";
import { CustomResourceHeader } from "./components/customResourceHeader";
import { CustomTimeSlotWrapper } from "./components/customTimeSlotWrapper";
import { CustomEvent } from "./components/customEvent/customEvent";
import "./schedules.css";
import { ScheduleDrawer } from "./components/drawer/scheduleDrawer";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { MobileSchedule } from "./mobileSchedule/mobileSchedule";
import classNames from "classnames";
import { useMemo } from "react";

export interface SchedulesProps {}

const locales = {
  "en-US": enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }), // week starts on Monday
  getDay,
  locales,
  addDays,
  subDays,
});

const DnDCalendar = withDragAndDrop(Calendar);

export const Schedules: React.FC<SchedulesProps> = (props) => {
  const {
    onNavigate,
    date,
    appointments,
    resourceMap,
    onUpdateAppointment,
    onNewSlotSelected,
    pendingAppointment,
    appointmentDrawer,
    setAppointmentDrawer,
    onAppointmentSelect,
    toggleAppointmentDrawer,
    selectedAppointmentEvent,
    setPendingAppointment,
    getSchedulesOfEmployees,
    currentShopTime,
    selectedEmployee,
    setSelectedEmployee,
    createNewPendingAppointment,
    shopId,
    onNewAppointmentInit,
  } = useSchedules();

  const events = [
    ...appointments,
    ...(pendingAppointment ? [pendingAppointment] : []),
  ];

  const currentShopEvents = useMemo(() => {
    return appointments.filter((app) => app.shops?.includes(shopId));
  }, [appointments, shopId]);

  const { isBelowSm } = useBreakpoint("sm");

  const renderCalendar = () => {
    if (isBelowSm) {
      return (
        <MobileSchedule
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          appointments={appointments}
          date={date}
          toggleAppointmentDrawer={toggleAppointmentDrawer}
          onAppointmentSelect={onAppointmentSelect}
          createNewPendingAppointment={createNewPendingAppointment}
          currentShopTime={currentShopTime}
        />
      );
    } else {
      return (
        <>
          {/* {currentShopEvents.length === 0 && (
            <div className="absolute flex flex-col items-center top-1/3 w-[calc(100dvw-48px)] gap-10 z-50">
              <EmptyState />
              <AsButton
                text={"Add a new appointment"}
                onClick={() => {
                  onNewAppointmentInit({});
                  toggleAppointmentDrawer(true, CrudAppointmentActions.CREATE);
                }}
              />
            </div>
          )} */}
          <DnDCalendar
            date={date}
            getNow={() => currentShopTime}
            scrollToTime={subHours(currentShopTime, 2)}
            defaultView={Views.DAY}
            localizer={localizer}
            events={events} // add pending appointments
            draggableAccessor={(event) => true}
            resources={resourceMap}
            onEventDrop={onUpdateAppointment}
            onNavigate={onNavigate}
            resizable
            onSelectEvent={onAppointmentSelect}
            resourceIdAccessor={(event: any) => event.resourceId}
            resourceTitleAccessor={(resource: any) => resource.resourceTitle}
            selectable
            onSelectSlot={onNewSlotSelected}
            showMultiDayTimes
            dayLayoutAlgorithm={"no-overlap"}
            step={10}
            timeslots={6}
            className="relative"
            onDragOver={(e) => console.log(e)}
            components={{
              toolbar: CustomToolbar,
              resourceHeader: CustomResourceHeader,
              timeSlotWrapper: CustomTimeSlotWrapper,
              event: (props) => <CustomEvent {...props} />,
            }}
          />
        </>
      );
    }
  };

  return (
    <div
      className={classNames({
        "h-[85dvh]": isBelowSm && appointmentDrawer.isOpen,
      })}
    >
      <ScheduleDrawer
        appointmentDrawer={appointmentDrawer}
        toggleAppointmentDrawer={toggleAppointmentDrawer}
        setAppointmentDrawer={setAppointmentDrawer}
        selectedAppointmentEvent={selectedAppointmentEvent}
        getSchedulesOfEmployees={getSchedulesOfEmployees}
        setPendingAppointment={setPendingAppointment}
        pendingAppointment={pendingAppointment}
      />
      {renderCalendar()}
    </div>
  );
};
