import { Path } from "app/path";
import { getSale, SaleStatusTypes, updateSale } from "app/redux/salesSlice";
import {
  CheckoutScreenTypes,
  SaleStatusConditionTypes,
} from "app/sales/useSales";
import { useQuery } from "app/utils/useQuery";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getFormValues } from "app/utils/getFormValues";
import {
  createPaymentTransaction,
  payWithCreditCard,
} from "app/redux/paymentTransactionSlice";
import { titleize } from "app/utils/string";
import { round } from "lodash";

export const tipOptions = [
  { label: "None", value: 0 },
  { label: "18%", value: 0.18 },
  { label: "20%", value: 0.2 },
  { label: "22%", value: 0.22 },
  { label: "Other", value: 0 },
];

export enum PaymentTypes {
  CASH = "cash",
  CREDIT_CARD = "credit_card",
}

export const usePaymentCheckout = ({
  saleId,
  saleStatusCondition,
  onArchiveSale,
}: {
  saleId: number;
  saleStatusCondition: SaleStatusConditionTypes | null;
  onArchiveSale: () => void;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const [sale, setSale] = useState<any>();
  const [tipsCents, setTipsCents] = useState(0);
  const [isLoadingSale, setisLoadingSale] = useState(true);
  const totalCents = sale?.totalCents - sale?.paidTipsCents + tipsCents;
  const total = (totalCents / 100).toFixed(2); // will have to change depending on tipcents
  const remainingBalanceCents = totalCents - sale?.paidBalanceCents;
  const remainingBalance = (remainingBalanceCents / 100).toFixed(2);
  const [showCreditCardOptions, setShowCreditCardOptions] = useState(false);
  const [payWithMode, setPayWithMode] = useState<PaymentTypes | null>(null);
  const [processingCreditCardCharge, setProcessingCreditCardCharge] =
    useState(false);
  const [selectedTipOption, setSelectedTipOption] = useState<any>();
  const [showOtherTipInput, setShowOtherTipInput] = useState(false);
  const [onShowDeleteModal, setOnShowDeleteModal] = useState(false);

  const closeSale = async () => {
    try {
      const newSale = await dispatch(
        updateSale({
          id: sale.id,
          status: SaleStatusTypes.CLOSED,
        }) as any
      ).unwrap();
      onPostPaymentRecord(newSale);
    } catch (error) {
      console.log(error);
    }
  };

  const recordPaidPayment = async (event: any) => {
    event.preventDefault();
    const { total: paidTotal } = getFormValues(event.target);
    const paidTotalCents = Number(paidTotal) * 100;
    try {
      const newPaymentTransaction = await dispatch(
        createPaymentTransaction({
          subtotalCents: sale.subtotalCents,
          taxCents: sale.taxCents,
          totalCents: paidTotalCents,
          tipsCents,
          saleId: sale.id,
          paymentMethod: payWithMode,
        }) as any
      ).unwrap();
      closeSale();
      toast.success(`${titleize(payWithMode!)} payment successfully recorded`);
    } catch (err) {
      console.log(err);
    }
  };

  const onPostPaymentRecord = (sale: any) => {
    setSale(sale);
    if (sale.status === SaleStatusTypes.CLOSED) {
      query.delete("status");
      query.delete("screen");
      query.set("screen", CheckoutScreenTypes.CLOSED);
      query.set("status", "closed");
      navigate(`${Path.SALES}?${query.toString()}`);
    }
    setPayWithMode(null);
  };

  const toOpenInfoScreen = () => {
    query.delete("screen");
    navigate(`${Path.SALES}?${query.toString()}`);
  };

  const payWithExistingCard = async () => {
    try {
      setProcessingCreditCardCharge(true);

      if (remainingBalanceCents === 0) {
        closeSale();
      }

      const transactionTaxCents = sale.taxCents - sale.paidTaxCents;

      await dispatch(
        payWithCreditCard({
          amount: Number(remainingBalance),
          totalCents,
          subtotalCents: remainingBalanceCents,
          taxCents: sale.taxCents - sale.paidTaxCents,
          tipsCents,
          saleId: sale!.id,
          name: sale!.client.name,
          tip: round(tipsCents / 100, 2),
          tax: round(transactionTaxCents / 100, 2),
          withExistingCard: true,
        }) as any
      ).unwrap();

      closeSale();
      setProcessingCreditCardCharge(false);
    } catch (error: any) {
      console.log(error);
      if (error?.data?.error?.status === "Declined") {
        toast.error("Card declined. Please try another card.");
      } else if (error?.data?.error?.status === "Error") {
        toast.error("An error occurred. Please try again.");
      } else {
        toast.error(
          "Unable to complete payment with this card. Please try another method"
        );
      }
      setProcessingCreditCardCharge(false);
    }
  };

  const onTipsChange = async (value: number, option?: any) => {
    setTipsCents(value);
    if (option) {
      setSelectedTipOption(option);
    }
    await dispatch(
      updateSale({
        id: sale.id,
        presaleTipsCents: value,
      }) as any
    );
  };

  useEffect(() => {
    (async () => {
      try {
        if (!saleId) return;
        setisLoadingSale(true);
        const sale = await dispatch(getSale(saleId) as any).unwrap();
        setSale(sale);
        setTipsCents(sale.paidTipsCents || sale.presaleTipsCents);
        setSelectedTipOption(
          sale.paidTipsCents > 0
            ? tipOptions[tipOptions.length - 1]
            : tipOptions[0]
        );
        setisLoadingSale(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [saleId]);

  const onArchiveSaleClick = () => {
    if (sale?.paidBalanceCents && sale?.paidBalanceCents > 0) {
      setOnShowDeleteModal(true);
    } else {
      onArchiveSale();
    }
  };

  return {
    tipsCents,
    setTipsCents,
    total,
    toOpenInfoScreen,
    showCreditCardOptions,
    setShowCreditCardOptions,
    payWithMode,
    setPayWithMode,
    recordPaidPayment,
    totalCents,
    remainingBalance,
    remainingBalanceCents,
    sale,
    isLoadingSale,
    onPostPaymentRecord,
    closeSale,
    processingCreditCardCharge,
    payWithExistingCard,
    selectedTipOption,
    setSelectedTipOption,
    showOtherTipInput,
    setShowOtherTipInput,
    onTipsChange,
    onArchiveSaleClick,
    onShowDeleteModal,
    setOnShowDeleteModal,
  };
};
