import * as React from "react";
import { Button, Listbox, ListboxItem } from "@nextui-org/react";
import { StaffSettingTypes, useStaff } from "./useStaff";
import { StaffDetails } from "./staffDetails/staffDetails";
import { PlusIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { StaffServices } from "./staffServices/staffServices";
import { StaffHours } from "./staffHours/staffHours";
import { InviteModal } from "./inviteModal/inviteModal";
import { partition, truncate } from "lodash";
import { AsBadge, AsButton } from "app/subframe";

export interface StaffProps {}

export const Staff: React.FC<StaffProps> = (props) => {
  const {
    onMenuPress,
    path,
    employees,
    onEmployeePress,
    selectedEmployee,
    isInviteModalOpen,
    setIsInviteModalOpen,
    onResendInvite,
    resendCooldown,
  } = useStaff();

  const view = () => {
    switch (path) {
      case StaffSettingTypes.STAFF_DETAILS:
        return <StaffDetails selectedEmployee={selectedEmployee} />;
      case StaffSettingTypes.SERVICES:
        return <StaffServices selectedEmployee={selectedEmployee} />;
      case StaffSettingTypes.HOURS:
        return <StaffHours selectedEmployee={selectedEmployee} />;
      default:
        return <StaffDetails selectedEmployee={selectedEmployee} />;
    }
  };

  const [invitedEmployees, activeEmployees] = partition(
    employees,
    (employee) => employee.invitePending
  );

  return (
    <>
      <InviteModal
        isOpen={isInviteModalOpen}
        onOpenChange={setIsInviteModalOpen}
      />
      <div className="flex">
        <div className="border-r-2 border-millet-400 bg-millet-200 h-screen w-full max-w-[240px] p-4 ">
          <AsButton
            variant="neutral-secondary"
            className="flex gap-2 w-full mb-3"
            onClick={() => setIsInviteModalOpen(true)}
          >
            <PlusIcon className="w-4 h-4 stroke-black" />
            <p className="text-body-bold font-body-bold text-neutral-900">
              Add staff member
            </p>
          </AsButton>
          <div className="flex flex-col gap-2">
            {activeEmployees.map((employee) => {
              return (
                <AsButton
                  variant="ghost"
                  key={employee.id}
                  className={classNames(
                    "w-full border-none flex justify-start",
                    {
                      "bg-millet-500": employee.id === selectedEmployee?.id,
                    }
                  )}
                  onClick={() => {
                    onEmployeePress({ employeeId: employee.id });
                  }}
                  size="sm"
                  text={truncate(employee.name, {
                    length: 20,
                    omission: "..",
                  })}
                />
              );
            })}
          </div>
          {invitedEmployees.length > 0 && (
            <div className="flex flex-col gap-2 mt-4">
              <p className="text-sm text-zinc-900">Invited Employees</p>
              {invitedEmployees.map((employee) => {
                return (
                  <Button
                    variant="ghost"
                    key={employee.id}
                    className={classNames(
                      "w-full border-none flex justify-start rounded-sm opacity-50 "
                    )}
                    onClick={() => {
                      onEmployeePress({ employeeId: employee.id });
                    }}
                  >
                    <p className={"text-sm text-zinc-700 font-semibold"}>
                      {employee.name}
                    </p>
                    <AsBadge variant="warning">Invited</AsBadge>
                  </Button>
                );
              })}
            </div>
          )}
        </div>
        <div className="flex w-full gap-6 max-w-[750px] mx-auto">
          <div className="flex flex-col w-full max-w-[200px]">
            <Listbox
              // className="flex flex-col p-2 justify-start items-start rounded shadow-dialog-shadow bg-white"
              className="p-2 justify-start items-start w-[200px] rounded shadow-dialog-shadow fixed top-10 z-10 bg-white"
              aria-label="Actions"
              classNames={{
                list: "gap-2",
              }}
              onAction={(key: any) => onMenuPress(key)}
            >
              <ListboxItem
                classNames={{
                  base: `${
                    path === StaffSettingTypes.STAFF_DETAILS
                      ? "bg-millet-500"
                      : ""
                  } rounded-sm !p-2 hover:!bg-millet-400`,
                }}
                key={StaffSettingTypes.STAFF_DETAILS}
              >
                <p
                  className={classNames(
                    path === StaffSettingTypes.STAFF_DETAILS
                      ? "font-semibold"
                      : ""
                  )}
                >
                  Staff Details
                </p>
              </ListboxItem>
              <ListboxItem
                classNames={{
                  base: `${
                    path === StaffSettingTypes.SERVICES ? "bg-millet-500" : ""
                  } rounded-sm !p-2 hover:!bg-millet-400`,
                }}
                key={StaffSettingTypes.SERVICES}
              >
                <p
                  className={classNames(
                    path === StaffSettingTypes.SERVICES ? "font-semibold" : ""
                  )}
                >
                  Services
                </p>
              </ListboxItem>
              <ListboxItem
                key={StaffSettingTypes.HOURS}
                classNames={{
                  base: `${
                    path === StaffSettingTypes.HOURS ? "bg-millet-500" : ""
                  } rounded-sm !p-2 hover:!bg-millet-400`,
                }}
              >
                <p
                  className={classNames(
                    path === StaffSettingTypes.HOURS ? "font-semibold" : ""
                  )}
                >
                  Hours
                </p>
              </ListboxItem>
            </Listbox>
            {selectedEmployee?.invitePending && (
              <Button
                color="warning"
                className="rounded-sm"
                onClick={() => onResendInvite(selectedEmployee)}
                isDisabled={resendCooldown !== null}
              >
                {resendCooldown !== null
                  ? `Resend Invite (${resendCooldown}s)`
                  : "Resend Invite"}
              </Button>
            )}
          </div>
          <div className="h-[100vh] py-10 overflow-auto w-full">
            {selectedEmployee && view()}
          </div>
        </div>
      </div>
    </>
  );
};
