import { useState, useMemo, useEffect } from "react";
import { addDays, startOfWeek, format, addHours } from "date-fns";
import { useQuery } from "app/utils/useQuery";
import { useSelector } from "react-redux";
import { selectShops } from "app/redux/shopsSlice";
import { find } from "lodash";
import { useNavigate } from "react-router-dom";
import { selectSchedules } from "app/redux/schedulesSlice";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { CrudAppointmentActions } from "../useSchedules";

export const useMobileSchedule = ({
  selectedEmployee,
  appointments,
  date,
  createNewPendingAppointment,
  toggleAppointmentDrawer,
}: {
  selectedEmployee: any;
  appointments: any[];
  date: Date;
  createNewPendingAppointment: any;
  toggleAppointmentDrawer: any;
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const shops = useSelector(selectShops);
  const schedules = useSelector(selectSchedules);
  const shop = find(shops, { id: Number(query.get("shopId")) });
  const [selectedEmployeeAppointments, setSelectedEmployeeAppointments] =
    useState<any>([]);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [expandedSlots, setExpandedSlots] = useState<number[]>([]);
  const [availabilityRanges, setAvailabilityRanges] = useState<any[]>([]);
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(date));
  const [selectedDate, setSelectedDate] = useState<Date>(date);

  const onDateChange = (newDate: Date) => {
    if (format(newDate, "yyyy-MM-dd") !== format(date, "yyyy-MM-dd")) {
      query.set("date", format(newDate, "yyyy-MM-dd"));
      navigate(`?${query.toString()}`);
    }
    setSelectedDate(newDate);
  };

  // Generate the days of the week based on the provided date
  const daysOfWeek = useMemo(() => {
    const start = startOfWeek(date);
    return Array.from({ length: 7 }, (_, i) => addDays(start, i));
  }, [date]);

  const onNewAppointmentInit = ({
    startTime = new Date(date),
    endTime = addHours(new Date(date), 1),
  }: {
    startTime?: Date;
    endTime?: Date;
  }) => {
    createNewPendingAppointment({
      employee: selectedEmployee,
      client: null,
      start: startTime,
      end: endTime,
      services: [],
    });

    toggleAppointmentDrawer(true, CrudAppointmentActions.CREATE);
  };

  const onWeekChange = (newWeekStart: Date) => {
    const newSelectedDate = newWeekStart;
    if (format(newSelectedDate, "yyyy-MM-dd") !== format(date, "yyyy-MM-dd")) {
      query.set("date", format(newSelectedDate, "yyyy-MM-dd"));
      navigate(`?${query.toString()}`);
    }
    setCurrentWeekStart(newWeekStart);
    setSelectedDate(newSelectedDate);
  };

  // Update URL query parameter when date changes
  useEffect(() => {
    query.set("date", format(selectedDate, "yyyy-MM-dd"));
    navigate(`?${query.toString()}`);
  }, [selectedDate]);

  useEffect(() => {
    const newEmployeeAppointments = appointments.filter(
      (appointment) => appointment.employee.id === selectedEmployee.id
    );
    setSelectedEmployeeAppointments(newEmployeeAppointments);
  }, [selectedEmployee, appointments]);

  useEffect(() => {
    const newTimeSlots: any[] = [];

    const selectedSchedule = schedules[format(date, "yyyy-MM-dd")];

    if (!selectedSchedule) return;

    // Find the employee data by ID within the selected schedule
    const employeeData = Object.values(selectedSchedule).find(
      (employee) => employee.employeeId === selectedEmployee.id
    ) as any;

    if (!employeeData) return;

    const { availability, appointmentGroups } = employeeData;

    if (availability && availability.status === "scheduled") {
      const startHour = parseInt(availability.timeStart.split(":")[0], 10);
      const endHour = parseInt(availability.timeEnd.split(":")[0], 10);

      // Iterate through each appointment group to accumulate booked times
      const allAppointments = appointmentGroups.flatMap(
        (group: any) => group.appointments
      );

      // Generate timeslots based on availability and booked appointments
      for (let hour = startHour; hour < endHour; hour++) {
        const slotStartTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hour,
          0,
          0
        );
        const slotEndTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hour + 1,
          0,
          0
        );

        // Check if this time slot overlaps with any booked appointment across all groups
        const hasAppointments = allAppointments.some((appointment: any) => {
          const appointmentStart = preserveUtcTimeToLocal(
            appointment.startTime
          );
          const appointmentEnd = preserveUtcTimeToLocal(appointment.endTime);

          // Check if the slot and appointment overlap
          return (
            appointmentStart < slotEndTime && appointmentEnd > slotStartTime
          );
        });

        newTimeSlots.push({
          hour,
          timeLabel: `${hour > 12 ? hour - 12 : hour} ${
            hour >= 12 ? "pm" : "am"
          }`,
          slotStartTime,
          slotEndTime,
          hasAppointments,
        });
      }
    }

    setTimeSlots(newTimeSlots);

    // Set initial expanded slots based on availability and booked appointments
    const initialExpandedSlots = newTimeSlots
      .filter((slot) => slot.hasAppointments)
      .map((slot) => slot.hour);
    setExpandedSlots(initialExpandedSlots);
  }, [selectedEmployee, date, schedules]);

  useEffect(() => {
    if (!timeSlots.length || !selectedEmployeeAppointments.length) {
      setAvailabilityRanges([]);
      return;
    }

    const newAvailabilityRanges: any[] = [];
    const scheduleStartTime = timeSlots[0].slotStartTime;
    const scheduleEndTime = timeSlots[timeSlots.length - 1].slotEndTime;

    // Sort selectedEmployeeAppointments by start time to easily find gaps
    const sortedAppointments = selectedEmployeeAppointments
      .map((appointment: any) => ({
        startTime: new Date(appointment.start),
        endTime: new Date(appointment.end),
      }))
      .sort((a: any, b: any) => a.startTime.getTime() - b.startTime.getTime());

    // Initialize the previous end time to the start of the schedule
    let previousEndTime = scheduleStartTime;

    for (const appointment of sortedAppointments) {
      if (previousEndTime < appointment.startTime) {
        // There's a gap between the previous end time and the current appointment's start time
        newAvailabilityRanges.push({
          startTime: previousEndTime,
          endTime: appointment.startTime,
        });
      }
      // Update previousEndTime to the end of the current appointment
      previousEndTime = appointment.endTime;
    }

    // Add the final range if there is time left after the last appointment
    if (previousEndTime < scheduleEndTime) {
      newAvailabilityRanges.push({
        startTime: previousEndTime,
        endTime: scheduleEndTime,
      });
    }

    setAvailabilityRanges(newAvailabilityRanges);
  }, [timeSlots, selectedEmployeeAppointments]);

  const getAppointmentsInSlot = (
    slot: any,
    appointments: any[],
    seenAppointments: number[]
  ) => {
    return appointments.filter((appointment: any) => {
      const isInSlot =
        appointment.start < slot.slotEndTime &&
        appointment.end > slot.slotStartTime;

      if (
        isInSlot &&
        !seenAppointments.includes(appointment.appointment.appointmentGroup.id)
      ) {
        seenAppointments.push(appointment.appointment.appointmentGroup.id); // Track the appointment group
        return true; // Include this appointment in the current slot
      }

      return false; // Skip this appointment
    });
  };

  return {
    daysOfWeek,
    selectedEmployeeAppointments,
    expandedSlots,
    toggleCollapse: (slotHour: number) => {
      setExpandedSlots((prev) =>
        prev.includes(slotHour)
          ? prev.filter((hour) => hour !== slotHour)
          : [...prev, slotHour]
      );
    },
    timeSlots,
    shop,
    onDateChange,
    availabilityRanges,
    onNewAppointmentInit,
    selectedDate,
    onWeekChange,
    currentWeekStart,
    getAppointmentsInSlot,
  };
};
