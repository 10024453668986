import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CustomClientOption } from "app/schedules/components/crudAppointmentScreens/components/customClientOption";
import { CustomEmployeeOption } from "app/schedules/components/crudAppointmentScreens/components/customEmployeeOption";
import { CustomServiceOption } from "app/schedules/components/crudAppointmentScreens/components/customServiceOption";
import { round, sumBy } from "lodash";
import { format } from "date-fns";
import * as React from "react";
import { useNewCheckout } from "./useNewCheckout";
import { SaleStatusConditionTypes } from "app/sales/useSales";
import { Icon } from "@subframe/core";
import {
  AsBadge,
  AsButton,
  AsIconButton,
  LineItems,
  reactSelectStyles,
} from "app/subframe";
import { Avatar } from "app/components/avatar/avatar";
import { DeleteSaleModal } from "app/sales/components/openSaleScreen/components/deleteSaleModal/deleteSaleModal";

export interface NewCheckoutProps {
  saleStatusCondition: SaleStatusConditionTypes | null;
  handleClientSearch: (inputValue: string, callback: any) => void;
  onCloseDrawer: () => void;
  onArchiveSale: () => void;
}

export const NewCheckout: React.FC<NewCheckoutProps> = ({
  saleStatusCondition,
  handleClientSearch,
  onCloseDrawer,
  onArchiveSale,
}) => {
  const {
    onSaveAndContinue,
    selectedClient,
    setSelectedClient,
    clients,
    onHandleClientChange,
    selectedServices,
    setEditingEmployeeInServiceIndex,
    editingEmployeeInServiceIndex,
    employeeSelectRef,
    handleEmployeeChange,
    employees,
    onRemoveService,
    onHandleAddService,
    availableServices,
    employee,
    serviceSelectValue,
    isLoading,
    sale,
    onShowDeleteModal,
    setOnShowDeleteModal,
    onArchiveSaleClick,
  } = useNewCheckout({
    onArchiveSale,
  });

  const getOpenSaleCondition = () => {
    switch (saleStatusCondition) {
      case SaleStatusConditionTypes.PAID_ONLINE_WITHOUT_TIPS: {
        return (
          <div className="flex w-full items-center gap-2">
            <Icon
              className="text-body font-body text-warning-500"
              name="FeatherAlertTriangle"
            />
            <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
              No tips have been collected for this sale.
            </span>
          </div>
        );
      }
      case SaleStatusConditionTypes.REMAINING_BALANCE: {
        return (
          <div className="flex w-full items-center gap-2">
            <Icon
              className="text-body font-body text-warning-500"
              name="FeatherAlertTriangle"
            />
            <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
              Remaining balance needs to be collected for this sale.
            </span>
          </div>
        );
      }
    }
  };

  return (
    <>
      <DeleteSaleModal
        isOpen={onShowDeleteModal}
        onOpenChange={setOnShowDeleteModal}
        onArchiveSale={onArchiveSale}
      />
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center gap-2 mt-1.5 mb-7">
          <div className="flex items-center gap-2">
            <p className="text-body-bold font-body-bold text-neutral-900">
              {sale?.id ? `Sale #${sale.id}` : "New Checkout"}
            </p>
            {sale?.id && (
              <AsBadge variant="warning" icon={null} iconRight={null}>
                Open
              </AsBadge>
            )}
          </div>
          <div className="flex items-center gap-4">
            <Dropdown
              classNames={{
                content: "rounded-sm",
              }}
            >
              <DropdownTrigger>
                <AsIconButton
                  variant="ghost"
                  size="sm"
                  leadingIcon={"FeatherMoreHorizontal"}
                />
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  variant="flat"
                  key={"delete"}
                  className="rounded-sm text-red-500"
                  color="danger"
                  onClick={() => onArchiveSaleClick()}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <AsIconButton
              variant="ghost"
              onClick={() => onCloseDrawer()}
              leadingIcon={"FeatherX"}
              size="xs"
            />
          </div>
        </div>
        <form
          className="flex flex-col h-[70dvh] pb-28 overflow-auto relative mobile:mt-4"
          onSubmit={onSaveAndContinue}
        >
          <div className="flex flex-col gap-6">
            {selectedClient ? (
              <div className="flex flex-col relative">
                <AsIconButton
                  variant="ghost"
                  onClick={() => setSelectedClient(null)}
                  className="rounded absolute top-0 right-0"
                  leadingIcon={"FeatherPen"}
                  size="xs"
                />
                <div className="flex pb-4">
                  <Avatar
                    src={selectedClient.avatar}
                    name={selectedClient.name}
                    size={56}
                    type="character"
                  />
                  <div className="flex flex-col justify-between ml-4">
                    <p className="font-semibold pt-0.5">
                      {selectedClient.name}
                    </p>
                    <p className="text-zinc-500 text-sm">
                      Client since{" "}
                      {format(selectedClient.createdAt, "M.d.yyyy")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-4">
                    <p className="text-zinc-500 w-[56px] text-sm">Phone:</p>
                    <p className="font-semibold ">
                      {selectedClient.phone || "(###) ###-####"}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <p className="text-zinc-500 w-[56px] text-sm">Email:</p>
                    <p className="font-semibold ">
                      {selectedClient.email || "###"}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <AsyncSelect
                cacheOptions
                loadOptions={handleClientSearch as any}
                onChange={onHandleClientChange}
                className="text-zinc-500 text-sm mobile:text-medium"
                placeholder="Search or select a client"
                components={{
                  Option: CustomClientOption,
                }}
                options={
                  clients.map((c: any) => ({
                    value: c.id,
                    label: c.name,
                    phone: c.phone,
                    email: c.email,
                    createdAt: c.createdAt,
                    avatar: "https://i.pravatar.cc/150?u=" + c.id,
                  })) as any
                }
                defaultOptions={clients.map((c: any) => ({
                  value: c.id,
                  label: c.name,
                  phone: c.phone,
                  email: c.email,
                  createdAt: c.createdAt,
                  avatar: "https://i.pravatar.cc/150?u=" + c.id,
                }))}
                styles={reactSelectStyles({
                  borderContainer: true,
                })}
              />
            )}
            {selectedServices &&
              selectedServices.map((service: any, index: any) => (
                <div
                  className="flex relative items-end justify-between"
                  key={index}
                >
                  <div className="flex flex-col gap-3">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      {service.title}
                    </p>
                    <div className="flex gap-4">
                      <div className="flex gap-1 text-sm">
                        <span className="text-zinc-500 text-sm">with</span>
                        <div
                          className="font-semibold text-zinc-900 cursor-pointer relative hover:underline"
                          onClick={() =>
                            setEditingEmployeeInServiceIndex(index)
                          }
                        >
                          {editingEmployeeInServiceIndex === index ? (
                            <div
                              ref={employeeSelectRef}
                              className="absolute -top-2 w-[200px]"
                            >
                              <Select
                                placeholder="Select an employee"
                                components={{
                                  Option: CustomEmployeeOption,
                                }}
                                onChange={(selectedOption) =>
                                  handleEmployeeChange(index, selectedOption)
                                }
                                className="text-zinc-500 text-sm mobile:text-medium"
                                options={employees.map((emp: any) => ({
                                  value: emp.id,
                                  label: emp.name,
                                  services: emp.services,
                                  availabilities: emp.availabilities,
                                  businessServiceId:
                                    selectedServices[index]?.businessService.id, // Pass the specific business service ID
                                }))}
                                onBlur={() =>
                                  setEditingEmployeeInServiceIndex(null)
                                }
                                value={
                                  selectedServices[index]?.employee
                                    ? {
                                        value:
                                          selectedServices[index]?.employee?.id,
                                        label:
                                          selectedServices[index]?.employee
                                            ?.name,
                                      }
                                    : null
                                }
                                menuIsOpen={
                                  editingEmployeeInServiceIndex === index
                                }
                                styles={reactSelectStyles({})}
                              />
                            </div>
                          ) : (
                            service?.employee?.name
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-body-bold font-body-bold text-neutral-900">
                      {service.price}
                    </p>
                    <AsIconButton
                      onClick={() => onRemoveService(index)}
                      variant="ghost"
                      className="absolute top-0 right-0"
                      leadingIcon={"FeatherTrash2"}
                      size="xs"
                    />
                  </div>
                </div>
              ))}
            <Select
              placeholder="+ Add service"
              components={{
                Option: CustomServiceOption,
              }}
              onChange={onHandleAddService}
              value={serviceSelectValue}
              className="text-zinc-500 text-sm mobile:text-medium"
              options={availableServices?.map((category: any) => ({
                label: category.name,
                options: category.services.map((service: any) => ({
                  value: service.id,
                  label: service.title,
                  price: service.price,
                  status: service.status,
                  employee,
                })),
              }))}
              styles={reactSelectStyles({})}
            />
          </div>
          <div className="flex flex-col gap-6 w-full bottom-0 left-0 p-2 fixed bg-white z-50">
            <div className="flex w-full flex-col items-start gap-2 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
              {getOpenSaleCondition()}
              <LineItems
                title="Subtotal"
                price={`$${round(
                  sumBy(selectedServices, "priceCents") / 100,
                  2
                ).toFixed(2)}`}
              />
            </div>
            <AsButton
              type="submit"
              disabled={
                isLoading || !selectedClient || !selectedServices.length
              }
              loader={isLoading}
              size="md"
              text="Save and Continue"
            />
          </div>
        </form>
      </div>
    </>
  );
};
