import { Path } from "app/path";
import {
  blacklistFromAccount,
  selectAccount,
  unblackListFromAccount,
} from "app/redux/accountSlice";
import { getClients, selectClients } from "app/redux/clientsSlice";
import { useQuery } from "app/utils/useQuery";
import { includes } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const perPageList = [30, 60, 90];
export const sortByList = [
  { title: "Recent", value: "updated_at" },
  { title: "Name", value: "name" },
  { title: "Email", value: "email" },
];

export const useClients = () => {
  const {
    clients,
    meta: { totalCount },
  } = useSelector(selectClients);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const account = useSelector(selectAccount);
  const pageParam = query.get("page");
  const clientIdParam = query.get("clientId");
  const [rowsPerPage, setRowsPerPage] = useState(perPageList[0]);
  const [sortBy, setSortBy] = useState(sortByList[0]);
  const [shouldShowClientDrawer, setShouldShowClientDrawer] = useState(false);
  const [shouldShowBlacklistDrawer, setShouldShowBlacklistDrawer] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const fetchClients = async () => {
    setLoading(true);
    try {
      await dispatch(
        getClients({
          perPage: rowsPerPage,
          sortBy: sortBy.value,
          page: Number(pageParam) || 1,
          sortOrder: sortBy.value === "created_at" ? "asc" : "desc",
        }) as any
      ).unwrap();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onClientRowClick = (id?: number) => {
    setShouldShowClientDrawer(true);
    query.delete("clientId");
    query.set("clientId", id!.toString());
    navigate(`${Path.CLIENTS}?${query.toString()}`);
  };

  const onOpenNewClientDrawer = () => {
    query.delete("clientId");
    navigate(`${Path.CLIENTS}?${query.toString()}`);
    if (!shouldShowClientDrawer) {
      setShouldShowClientDrawer(true);
    }
  };

  const onBlacklistAdd = async (
    identifier: string,
    identifierType?: string
  ) => {
    try {
      await dispatch(
        blacklistFromAccount({
          identifier: identifier.toLowerCase(),
          identifierType: identifierType || "email",
        }) as any
      ).unwrap();
      toast.success(`${identifier} blacklisted successfully`);
    } catch (error: any) {
      if (
        includes(error?.data?.details?.identifier, "has already been taken")
      ) {
        toast.error(`${identifier} is already blacklisted`);
        return;
      }
      console.error(error);
    }
  };

  const onBlacklistRemove = async (
    identifier: string,
    identifierType?: string
  ) => {
    try {
      await dispatch(
        unblackListFromAccount({
          identifier,
          identifierType: "email",
        }) as any
      ).unwrap();
      toast.success(`${identifier} has been unblacklisted successfully`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      fetchClients();
    })();
  }, [rowsPerPage, sortBy, pageParam]);

  useEffect(() => {
    if (clientIdParam) {
      setShouldShowClientDrawer(true);
    }
  }, []);

  useEffect(() => {
    if (!shouldShowClientDrawer && clientIdParam) {
      query.delete("clientId");
      navigate(`${Path.CLIENTS}?${query.toString()}`);
    }
  }, [shouldShowClientDrawer]);

  return {
    clients,
    rowsPerPage,
    setRowsPerPage,
    perPageList,
    sortBy,
    setSortBy,
    shouldShowClientDrawer,
    setShouldShowClientDrawer,
    onClientRowClick,
    clientIdParam,
    onOpenNewClientDrawer,
    loading,
    totalCount,
    shouldShowBlacklistDrawer,
    setShouldShowBlacklistDrawer,
    onBlacklistAdd,
    onBlacklistRemove,
    account,
  };
};
