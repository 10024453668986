import { createConsumer } from "@rails/actioncable";

const cable = createConsumer(
  process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_CABLE_URL
    : "ws://localhost:4200/cable"
);

export default cable;
