import { selectAccount, updateAccount } from "app/redux/accountSlice";
import {
  downloadConsentForm,
  updateConsentForm,
  createConsentForm,
} from "app/redux/consentFormSlice";
import { createWaiver, downloadWaiver } from "app/redux/waiverSlice";
import { handleDownloadFile } from "app/utils/downloadFile";
import { intersectionBy } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useBusinessPolicies = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const [consentServices, setConsentServices] = useState<any[]>([]);

  const onUpdateAccount = (keyValue: any) => {
    try {
      dispatch(updateAccount(keyValue) as any).unwrap();
    } catch (error: any) {
      toast.error("Failed to update business policies");
      console.log(error);
    }
  };

  const uploadConsent = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      toast.error("No file selected.");
      return;
    }

    try {
      if (!account.consentForm?.id) {
        // Create a new consent form
        await dispatch(createConsentForm({ file }) as any).unwrap();
        toast.success("Consent form created and file uploaded successfully!");
      } else {
        // Update the existing consent form
        await dispatch(
          updateConsentForm({ id: account.consentForm.id, file }) as any
        ).unwrap();
        toast.success("Consent form updated with new file!");
      }
    } catch (error: any) {
      toast.error("Failed to upload consent form.");
      console.error("Error uploading consent form:", error);
    }
  };

  const onDownloadConsent = async (consentFormId: number) => {
    try {
      const { url } = await dispatch(
        downloadConsentForm(consentFormId) as any
      ).unwrap();
      handleDownloadFile(url);
    } catch (error: any) {
      toast.error("Failed to download consent.");
      console.error("Error downloading consent:", error);
    }
  };

  const onHandleConsentServiceChange = async (selectedOptions: any) => {
    const selectedServices = selectedOptions.map((option: any) => option.value);
    try {
      if (!account.consentForm?.id) {
        // Create a new consent form
        await dispatch(
          createConsentForm({ serviceIds: selectedServices }) as any
        ).unwrap();
        toast.success("Consent form created with selected services!");
      } else {
        // Update the existing consent form
        await dispatch(
          updateConsentForm({
            id: account.consentForm.id,
            serviceIds: selectedServices,
          }) as any
        ).unwrap();
      }
    } catch (error: any) {
      toast.error("Failed to update consent services.");
      console.error("Error updating consent services:", error);
    }
  };

  const uploadWaiver = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      toast.error("No file selected.");
      return;
    }

    try {
      await dispatch(createWaiver(file) as any).unwrap();
      toast.success("Waiver uploaded successfully!");
    } catch (error: any) {
      toast.error("Failed to upload waiver.");
      console.error("Error uploading waiver:", error);
    }
  };

  const onDownloadWaiver = async (waiverId: number) => {
    try {
      const { url } = await dispatch(downloadWaiver(waiverId) as any).unwrap();
      handleDownloadFile(url);
    } catch (error: any) {
      toast.error("Failed to download waiver.");
      console.error("Error downloading waiver:", error);
    }
  };

  useEffect(() => {
    const consentServicesId = account?.consentForm?.serviceIds || [];

    const consentServices = intersectionBy(
      account.services,
      consentServicesId.map((id) => ({ id })),
      "id"
    );

    setConsentServices(consentServices);
  }, [account]);

  return {
    account,
    onUpdateAccount,
    uploadWaiver,
    onDownloadWaiver,
    uploadConsent,
    onDownloadConsent,
    consentServices,
    setConsentServices,
    onHandleConsentServiceChange,
  };
};
