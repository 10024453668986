import { getAppointmentGroup } from "app/redux/appointmentGroupsSlice";
import { formatAsUTC, preserveUtcTimeToLocal } from "app/utils/formatDate";
import { useQuery } from "app/utils/useQuery";
import { addMinutes, addSeconds, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { replace } from "lodash";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export const useConfirmAppointment = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isReschedule = query.get("reschedule") === "true";
  const { appointmentGroupId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [appointmentGroup, setAppointmentGroup] = React.useState<any>(null);

  const generateICSFile = () => {
    const { appointments, startTime, duration, updatedAt, timezone } =
      appointmentGroup;
    const endTime = addMinutes(startTime, duration);
    const location = appointments[0]?.shop.addressLine1;
    const client = appointments[0]?.client.name;

    const sequence = Math.floor(new Date(updatedAt).getTime() / 1000); // Convert to seconds since epoch
    const uid = `${appointmentGroup.id}@heyallset.com`;
    const dtStamp = format(new Date(), "yyyyMMdd'T'HHmmss'Z'");

    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startTimeToUse = addSeconds(startTime, timezone.rawOffset * -1);
    const endTimeToUse = addSeconds(endTime, timezone.rawOffset * -1);

    const formattedStartTime = format(startTimeToUse, "yyyyMMdd'T'HHmmss");
    const formattedEndTime = format(endTimeToUse, "yyyyMMdd'T'HHmmss");

    const escapeText = (text: string) =>
      text.replace(/,/g, "\\,").replace(/;/g, "\\;").replace(/\n/g, "\\n");

    const icsContent = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `UID:${uid}`,
      `SEQUENCE:${sequence}`,
      `DTSTAMP:${dtStamp}`,
      `SUMMARY:${escapeText(
        `${isReschedule ? "UPDATED" : ""}Appointment with ${client}`
      )}`,
      `DESCRIPTION:${escapeText(`${appointments[0].services[0].title}`)}`,
      `LOCATION:${escapeText(location)}`,
      `DTSTART;TZID=${localTimezone}:${formattedStartTime}`,
      `DTEND;TZID=${localTimezone}:${formattedEndTime}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\r\n"); // Ensure proper line endings

    const blob = new Blob([icsContent], { type: "text/calendar" });
    // return URL.createObjectURL(blob);
    const icsUrl = URL.createObjectURL(blob);

    // Attempt to open the file
    window.open(icsUrl, "_blank");

    // Clean up the URL object after some time
    setTimeout(() => {
      URL.revokeObjectURL(icsUrl);
    }, 5000);
  };

  const init = async () => {
    setLoading(true);
    try {
      const ag = await dispatch(
        getAppointmentGroup(Number(appointmentGroupId)) as any
      ).unwrap();
      setAppointmentGroup(ag);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    loading,
    appointmentGroup,
    generateICSFile,
    isReschedule,
  };
};
