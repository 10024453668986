import { Icon } from "@subframe/core";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { AsButton, AsIconButton, DialogLayout } from "app/subframe";
import * as React from "react";

export interface DeleteClientModalProps {
  isOpen: boolean;
  onOpenChange: any;
  onArchiveClient: (clientId: number) => void;
  isArchivingClient: boolean;
  client: any;
}

export const DeleteClientModal: React.FC<DeleteClientModalProps> = ({
  isOpen,
  onOpenChange,
  onArchiveClient,
  isArchivingClient,
  client,
}) => {
  return (
    <FadeInSlide direction="bottom" className="absolute">
      <DialogLayout
        open={isOpen}
        onOpenChange={onOpenChange}
        className="z-[1000]"
      >
        <div className="flex max-w-112 relative flex-col items-center gap-4 rounded-lg bg-default-background px-6 py-6 shadow-dialog-shadow">
          <div className={"group/fe4ad82d flex w-full items-center gap-2"}>
            <Icon
              className="text-body font-body text-error-500"
              name="FeatherAlertTriangle"
            />
            <span
              className={
                "grow shrink-0 basis-0 text-[18px] font-[500] text-neutral-900"
              }
            >
              Delete {client.name}?
            </span>
            <AsIconButton
              className="absolute top-4 right-4"
              variant="ghost"
              leadingIcon="FeatherX"
              trailingIcon={null}
              size="xs"
              onClick={() => {
                onOpenChange(false);
              }}
            />
          </div>
          <div className="flex flex-col items-center gap-10 mt-2 mb-4 mobile:gap-5">
            <div className="flex flex-col items-center gap-2">
              <p>
                Deleting will remove {client.name} from your clients list.
                Previous, ongoing, and future sales & appointments regarding
                this client will not be affected.
              </p>
            </div>
          </div>
          <div className="flex items-center w-full">
            <AsButton
              variant="default"
              className="w-full !border-transparent !py-6 bg-red-600 hover:bg-red-500 active:bg-red-500 !text-white"
              text="Delete Client"
              loader={isArchivingClient}
              onClick={() => onArchiveClient(client.id)}
            />
          </div>
        </div>
      </DialogLayout>
    </FadeInSlide>
  );
};
