import * as React from "react";
import { Provider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'

export interface RollbarProps {
  children?: React.ReactNode;
}

export const Rollbar: React.FC<RollbarProps> = ({ children }) => {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: "production",
  };

  if (process.env.REACT_APP_ENV !== "production") {
    return <>{children}</>;
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};
