import * as React from "react";
import { UserAuth, UserContextType } from "app/context/AuthContext";
import { getFormValues } from "app/utils/getFormValues";
import { useNavigate } from "react-router";
import { Path } from "app/path";
import firebaseAuth from "app/utils/firebase";
import { toast } from "react-toastify";
import { useQuery } from "app/utils/useQuery";

export const useSignin = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const { emailPassSignIn, init } = UserAuth() as UserContextType;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleSigninSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = getFormValues(event.target);

    try {
      setLoading(true);
      await emailPassSignIn(email, password);
      const user = firebaseAuth.currentUser;
      await init(user);
      const redirectUrl = query.get("redirectUrl");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate(Path.HOME);
      }
    } catch (error: any) {
      if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/user-not-found"
      ) {
        toast.error("Your email or password is incorrect");
      }
    } finally {
      setLoading(false);
    }
  };

  return { handleSigninSubmit, loading, showPassword, setShowPassword };
};
