import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { getAccount } from "./accountSlice";

export interface WaiverSliceState {
  id: number;
  createdAt: string;
  updatedAt: string;
}

const initialState = {} as WaiverSliceState;

export const createWaiver = createAsyncThunk(
  "waivers/create",
  async (file: File, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("waiver[file]", file);

      const { data } = await http.post("/v1/waivers", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      thunkAPI.dispatch(getAccount()).unwrap();
      return data; // Response from the backend, e.g., { url: "waiver_url" }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const downloadWaiver = createAsyncThunk(
  "waivers/download",
  async (waiverId: number, thunkAPI) => {
    try {
      const { data } = await http.get(`/v1/waivers/${waiverId}/download`);

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
