import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { getAccount } from "./accountSlice";

// Define the ConsentForm state interface
export interface ConsentFormSliceState {
  id: number;
  serviceIds: number[];
  createdAt: string;
  updatedAt: string;
}

const initialState = {} as ConsentFormSliceState;

// Create a new consent form
export const createConsentForm = createAsyncThunk(
  "consentForms/create",
  async (
    { file, serviceIds }: { file?: File; serviceIds?: number[] },
    thunkAPI
  ) => {
    try {
      const formData = new FormData();

      // Add file and serviceIds to the payload under the "consent_form" wrapper
      if (file) {
        formData.append("consent_form[file]", file);
      }
      if (serviceIds) {
        formData.append(
          "consent_form[service_ids]",
          JSON.stringify(serviceIds)
        );
      }

      // API request to create a consent form
      const { data } = await http.post(`/v1/consent_forms`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      thunkAPI.dispatch(getAccount()).unwrap(); // Refresh account state
      return data; // Return response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Update an existing consent form
export const updateConsentForm = createAsyncThunk(
  "consentForms/update",
  async (
    {
      id,
      file,
      serviceIds,
    }: { id: number; file?: File; serviceIds?: number[] },
    thunkAPI
  ) => {
    try {
      const formData = new FormData();

      // Add file and serviceIds to the payload under the "consent_form" wrapper
      if (file) {
        formData.append("consent_form[file]", file);
      }
      if (serviceIds) {
        formData.append(
          "consent_form[service_ids]",
          JSON.stringify(serviceIds)
        );
      }

      // API request to update a consent form
      const { data } = await http.patch(`/v1/consent_forms/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      thunkAPI.dispatch(getAccount()).unwrap(); // Refresh account state
      return data; // Return response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Download a consent form
export const downloadConsentForm = createAsyncThunk(
  "consentForms/download",
  async (consentFormId: number, thunkAPI) => {
    try {
      // API request to download a consent form
      const { data } = await http.get(
        `/v1/consent_forms/${consentFormId}/download`
      );

      return data; // Return response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
