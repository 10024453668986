import { Drawer } from "app/components/drawer/drawer";
import { AsButton, AsInput } from "app/subframe";
import * as React from "react";

export interface BlackListDrawerProps {
  shouldShowBlacklistDrawer: boolean;
  setShouldShowBlacklistDrawer: (value: boolean) => void;
  account: any;
  onBlacklistAdd: (email: string, identifierType?: string) => void;
  onBlacklistRemove: (email: string, identifierType?: string) => void;
}

export const BlackListDrawer: React.FC<BlackListDrawerProps> = ({
  shouldShowBlacklistDrawer,
  setShouldShowBlacklistDrawer,
  account,
  onBlacklistAdd,
  onBlacklistRemove,
}) => {
  const { blacklist } = account;
  const [emailToBlacklist, setEmailToBlacklist] = React.useState("");
  const submitBlacklist = async () => {
    onBlacklistAdd(emailToBlacklist);
    setEmailToBlacklist("");
  };

  return (
    <Drawer
      isOpen={shouldShowBlacklistDrawer}
      setIsOpen={setShouldShowBlacklistDrawer}
      backdrop={false}
    >
      <p className="font-medium mb-7">Blacklist</p>
      <p className="mb-4">
        Enter an email, then click <span className="font-bold">Add</span> to
        blacklist and block their bookings.
      </p>
      <AsInput
        value={emailToBlacklist}
        onChange={(e) => setEmailToBlacklist(e.target.value)}
        trailingComponent={
          <AsButton
            leadingIcon="FeatherPlus"
            text="Add"
            variant="secondary"
            size="sm"
            className="mr-2"
            onClick={() => submitBlacklist()}
          />
        }
        textPosition="left"
        placeholder="Enter the user's email here"
      />
      <div className="flex h-px grow shrink-0 basis-0 flex-col my-6 items-center gap-2 bg-neutral-100" />
      <p className="text-sm mb-4 text-neutral-500">
        {blacklist?.length || 0} blocked
      </p>
      <div className="flex flex-col gap-2 h-[70vh] overflow-auto">
        {blacklist?.map((e: any) => (
          <div key={e.id} className="flex items-center justify-between py-2">
            <p className="text-neutral-700 text-sm font-medium">
              {e.identifier}
            </p>
            <AsButton
              leadingIcon={"FeatherTrash"}
              variant="ghost"
              iconColor="text-error-700"
              size="xs"
              onClick={() => onBlacklistRemove(e.identifier)}
            />
          </div>
        ))}
      </div>
    </Drawer>
  );
};
