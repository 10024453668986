import { selectEmployees } from "app/redux/employeesSlice";
import { getSchedules, selectSchedules } from "app/redux/schedulesSlice";
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  startOfDay,
} from "date-fns";
import { useEffect, useState } from "react";
import { addDays, subDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { updateAppointment } from "app/redux/appointmentsSlice";
import { find, flattenDeep, includes, some } from "lodash";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";
import { Path } from "app/path";
import { adjustDateToUTC } from "app/utils/formatTime";
import { AvailabilityStatusTypes } from "app/redux/availabilitiesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { toZonedTime } from "date-fns-tz";
import cable from "app/cable";
import { selectAccount } from "app/redux/accountSlice";

interface ResourceProps {
  resourceId: number;
  resourceTitle: string;
  avatar: string;
}

export enum OtherEventTypes {
  OFF = "off",
}

export enum CalendarDayNavigateTypes {
  PREV = "prev",
  NEXT = "next",
  TODAY = "today",
}

export enum CrudAppointmentActions {
  READ = "read",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export interface PendingAppointmentProps {
  employeeId: number;
  startTime: string;
  endTime: string;
  action: CrudAppointmentActions;
}

export const useSchedules = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const account = useSelector(selectAccount);
  const navigate = useNavigate();
  const schedules = useSelector(selectSchedules);
  const shops = useSelector(selectShops);
  const [currentShopTime, setCurrentShopTime] = useState(new Date());
  const employees = useSelector(selectEmployees);
  const selectedEmployeeId = query.get("employeeId")
    ? parseInt(query.get("employeeId")!)
    : employees[0]?.id;
  const [selectedEmployee, setSelectedEmployee] = useState<any>(
    find(employees, { id: selectedEmployeeId })
  );
  const [date, setDate] = useState<Date>(currentShopTime);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [resourceMap, setResourceMap] = useState<ResourceProps[]>([]);
  const [pendingAppointment, setPendingAppointment] = useState<any>();
  const [selectedAppointmentEvent, setSelectedAppointmentEvent] =
    useState(null);
  const [appointmentDrawer, setAppointmentDrawer] = useState({
    isOpen: false,
    type: null,
  } as { isOpen: boolean; type: CrudAppointmentActions | null });

  const onNavigate = async (newDate: Date, view: any, action: any) => {
    let updatedDate: Date;

    switch (action) {
      case CalendarDayNavigateTypes.TODAY:
        updatedDate = new Date(); // Get today's date
        break;
      case CalendarDayNavigateTypes.PREV:
        updatedDate = subDays(newDate, 1); // Subtract one day
        break;
      case CalendarDayNavigateTypes.NEXT:
        updatedDate = addDays(newDate, 1); // Add one day
        break;
      default:
        return; // No action, no update
    }

    // Update the state with the new date
    await getSchedulesOfEmployees({ currentDate: updatedDate });
    query.delete("date");
    query.delete("appointmentId");
    query.delete("phantomId");
    query.set("date", format(updatedDate, "yyyy-MM-dd"));
    navigate(`${Path.HOME}?${query.toString()}`);
    setDate(updatedDate); // Ensure `setDate` is defined in your component
  };

  const getMappedAppointments = (
    employeeSchedulesArray: any[],
    currentDate: any
  ) => {
    const mappedAppointments: any[] = [];
    employeeSchedulesArray.forEach(
      ({ employeeId, appointments, availability }: any) => {
        // Handle full day unavailability

        if (availability?.status !== AvailabilityStatusTypes.SCHEDULED) {
          const start = `${format(currentDate, "yyyy-MM-dd")}T00:00:00`;
          const end = `${format(currentDate, "yyyy-MM-dd")}T23:59:59`;

          mappedAppointments.push({
            title: "Break",
            start: new Date(start),
            end: new Date(end),
            resourceId: [employeeId],
            pending: false,
            employee: employees.find((employee) => employee.id === employeeId),
          });
        } else {
          // Create "unavailable" event before the first available time
          if (availability.timeStart) {
            const start = `${format(currentDate, "yyyy-MM-dd")}T00:00:00`;
            const end = `${format(currentDate, "yyyy-MM-dd")}T${
              availability.timeStart
            }:00`;

            mappedAppointments.push({
              title: "off",
              start: new Date(start),
              end: new Date(end),
              resourceId: [employeeId],
              pending: false,
              employee: employees.find(
                (employee) => employee.id === employeeId
              ),
            });
          }

          // Create "unavailable" event after the last available time
          if (availability.timeEnd) {
            const start = `${format(currentDate, "yyyy-MM-dd")}T${
              availability.timeEnd
            }:00`;
            const end = `${format(currentDate, "yyyy-MM-dd")}T23:59:59`;

            mappedAppointments.push({
              title: "off",
              start: new Date(start),
              end: new Date(end),
              resourceId: [employeeId],
              pending: false,
              employee: employees.find(
                (employee) => employee.id === employeeId
              ),
            });
          }
        }

        // Map actual appointments for this resource
        appointments.forEach((appointment: any) => {
          const start = preserveUtcTimeToLocal(appointment.startTime);
          const end = preserveUtcTimeToLocal(appointment.endTime);

          mappedAppointments.push({
            services: appointment.services,
            client: appointment.client,
            title: `${appointment.services[0].title}`,
            start,
            end,
            appointment,
            resourceId: [employeeId],
            shop: appointment.shop,
            pending: false,
            employee: employees.find((employee) => employee.id === employeeId),
          });
        });
      }
    );

    return mappedAppointments;
  };

  const getMappedResources = (employeeSchedulesArray: any) => {
    const mappedEmployeeResources: ResourceProps[] = [];

    employeeSchedulesArray.forEach(
      ({
        employeeId,
        employeeName,
        availability,
        employeeAvatar,
        appointments,
        shopIds,
      }: any) => {
        if (availability?.status === AvailabilityStatusTypes.UNSCHEDULED) {
          return;
        }

        if (!includes(shopIds, Number(query.get("shopId")))) {
          return;
        }
        mappedEmployeeResources.push({
          resourceId: employeeId,
          resourceTitle: employeeName,
          avatar: employeeAvatar,
        });
      }
    );

    return mappedEmployeeResources;
  };

  const calendarInit = (newSchedules: any[], currentDate: any) => {
    if (!newSchedules || newSchedules.length === 0) {
      setAppointments([]);
      return;
    } else {
      const mappedResources = getMappedResources(newSchedules);
      setResourceMap(mappedResources);

      const mappedAppointments = getMappedAppointments(
        newSchedules,
        currentDate
      );

      setAppointments(mappedAppointments);
    }
  };

  const getSchedulesOfEmployees = async ({
    currentDate = currentShopTime,
    specificEmployeeId,
  }: {
    currentDate?: Date;
    specificEmployeeId?: number;
  }) => {
    const employeeIds = employees
      .filter((employee) =>
        some(employee.shops, (shop) => shop.id === Number(query.get("shopId")))
      )
      .map((employee) => employee.id);

    try {
      const newSchedules = await dispatch(
        getSchedules({
          employeeIds: specificEmployeeId ? [specificEmployeeId] : employeeIds,
          date: currentDate,
        }) as any
      ).unwrap();

      calendarInit(newSchedules, currentDate);
    } catch (error) {
      console.log("Error fetching schedules:", error);
    }
  };

  const onUpdateAppointment = async ({
    event,
    resourceId,
    start,
    end,
  }: any) => {
    if (event.pending) {
      return createNewPendingAppointment({ ...event, start, end });
    } else if (
      resourceId !== event.services?.[0]?.employee?.id ||
      event.services.length === 0
    ) {
      return;
    } else {
      const appointment = event.appointment;
      const startTime = format(start, "EEE MMM dd yyyy HH:mm:ss");
      const endTime = format(end, "EEE MMM dd yyyy HH:mm:ss");

      try {
        await dispatch(
          updateAppointment({
            appointmentId: appointment.id,
            startTime,
            endTime,
            employeeId: resourceId,
          }) as any
        ).unwrap();
        await getSchedulesOfEmployees({
          currentDate: startOfDay(date),
          specificEmployeeId: selectedEmployee?.id,
        });
      } catch (error) {
        console.log("Error updating appointment:", error);
      }
    }
  };

  const onNewAppointmentInit = ({
    startTime = new Date(date),
    endTime = addHours(new Date(date), 1),
  }: {
    startTime?: Date;
    endTime?: Date;
  }) => {
    createNewPendingAppointment({
      employee: selectedEmployee,
      client: null,
      start: startTime,
      end: endTime,
      services: [],
    });

    toggleAppointmentDrawer(true, CrudAppointmentActions.CREATE);
  };

  const onNewSlotSelected = ({ start, end, resourceId, action }: any) => {
    if (action === "doubleClick") {
      return;
    }

    setAppointmentDrawer({ isOpen: false, type: null });

    const employeeId = resourceId;

    createNewPendingAppointment({
      employee: employees.find((employee) => employee.id === employeeId),
      services: [],
      client: null,
      start,
      end,
    });

    setAppointmentDrawer({
      isOpen: true,
      type: CrudAppointmentActions.CREATE,
    });
  };

  const onAppointmentSelect = (event: any) => {
    if (event.title === OtherEventTypes.OFF || event.pending) {
      return;
    }
    setSelectedAppointmentEvent(event);
    console.log("event", event);
    query.delete("appointmentId");
    query.delete("phantomId");
    query.set("appointmentId", event.appointment.id);
    if (event.appointment.phantomId) {
      query.set("phantomId", event.appointment.phantomId);
    }
    navigate(`${Path.HOME}?${query.toString()}`);
    setAppointmentDrawer({ isOpen: true, type: CrudAppointmentActions.READ });
  };

  const toggleAppointmentDrawer = (
    open: boolean,
    type?: CrudAppointmentActions
  ) => {
    setAppointmentDrawer({ isOpen: open, type: type || null });
  };

  const createNewPendingAppointment = (event: any) => {
    query.delete("appointmentId");
    query.delete("phantomId");
    navigate(`${Path.HOME}?${query.toString()}`);
    const newAppointment = {
      employee: event.employee,
      services: event.services,
      client: event.client,
      title: "New Appointment",
      start: event.start,
      end:
        differenceInMinutes(event.end, event.start) < 40
          ? addMinutes(event.start, 40)
          : event.end,
      resourceId: [event.employee.id],
      pending: true,
    };
    setPendingAppointment(newAppointment);
  };

  useEffect(() => {
    if (query.get("appointmentId")) {
      const appointmentId = parseInt(query.get("appointmentId") as string);
      const appointment = find(
        flattenDeep(appointments),
        (appointment) => appointment.appointment?.id === appointmentId
      );
      if (appointment) {
        setSelectedAppointmentEvent(appointment);
        setAppointmentDrawer({
          isOpen: true,
          type: CrudAppointmentActions.READ,
        });
      }
    }
  }, [appointments]);

  useEffect(() => {
    const date = query.get("date");
    const shopId = query.get("shopId");

    if (!shopId || !date) {
      // If shopId or date is missing, append them and navigate
      if (!shopId) {
        query.set("shopId", shops[0]?.id.toString());
      }
      if (!date) {
        query.set("date", format(new Date(), "yyyy-MM-dd"));
      }
      navigate(`${Path.HOME}?${query.toString()}`);
      return;
    }

    const adjustedDate = adjustDateToUTC(date);
    setDate(adjustedDate);

    getSchedulesOfEmployees({
      currentDate: adjustedDate,
      specificEmployeeId: selectedEmployee?.id,
    });
    setCurrentShopTime(
      toZonedTime(
        new Date(),
        find(shops, { id: Number(query.get("shopId")) })?.timezone.timeZoneId
      )
    );
  }, [query.get("shopId"), query.get("date")]); // Remove selectedEmployee

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      {
        channel: "SchedulesChannel",
        account_id: account.id,
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (data) => {
          getSchedulesOfEmployees({
            currentDate: adjustDateToUTC(data.date),
          });
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    getSchedulesOfEmployees,
    onNavigate,
    date,
    schedules,
    appointments,
    resourceMap,
    onUpdateAppointment,
    onNewSlotSelected,
    appointmentDrawer,
    setAppointmentDrawer,
    onAppointmentSelect,
    selectedAppointmentEvent,
    setSelectedAppointmentEvent,
    toggleAppointmentDrawer,
    pendingAppointment,
    setPendingAppointment,
    currentShopTime,
    selectedEmployee,
    setSelectedEmployee,
    createNewPendingAppointment,
    shopId: query.get("shopId"),
    onNewAppointmentInit,
  };
};
