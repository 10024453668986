import * as React from "react";
import { useConfirmAppointment } from "./useConfirmAppointment";
import { AsButton } from "app/subframe";
import * as SubframeCore from "@subframe/core";
import { Loading } from "app/components/loading/loading";
import { Link } from "react-router-dom";
import { Path } from "app/path";
import { format } from "date-fns";

export interface ConfirmAppointmentProps {}

export const ConfirmAppointment: React.FC<ConfirmAppointmentProps> = ({}) => {
  const { loading, appointmentGroup, generateICSFile, isReschedule } =
    useConfirmAppointment();

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2">
        <Loading type="dark" />
      </div>
    );
  }

  return (
    <div className="flex w-112 grow h-full shrink-0 basis-0 justify-self-center flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:flex-nowrap mobile:gap-6 mobile:px-2 mobile:py-0">
      <div className="flex w-full grow shrink-0 basis-0 flex-col items-center justify-center gap-4">
        <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
          {appointmentGroup ? (
            <>
              <div className="flex w-full items-center gap-2">
                <SubframeCore.Icon
                  className="text-body font-body text-success-500"
                  name="FeatherCheckCircle"
                />
                <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-900">
                  Appointment {isReschedule ? "rescheduled" : "confirmed"}.
                </span>
              </div>
              <AsButton
                className="h-14 w-full flex-none"
                variant="secondary"
                leadingIcon="FeatherCalendar"
                trailingIcon={null}
                text="Add this to your calendar"
                size="lg"
                loader={false}
                disabled={false}
                onClick={() => {
                  if (appointmentGroup) {
                    generateICSFile();
                  }
                }}
                disabledSecondary={false}
              />
              <Link
                to={`${Path.HOME}?appointmentId=${
                  appointmentGroup?.appointments[0]?.id
                }&date=${format(
                  new Date(appointmentGroup.startTime),
                  "yyyy-MM-dd"
                )}`}
                className="w-full"
              >
                <AsButton
                  className="h-14 w-full flex-none"
                  variant="secondary"
                  leadingIcon="FeatherExternalLink"
                  trailingIcon={null}
                  text="Go to appointment"
                  size="lg"
                  loader={false}
                  disabled={false}
                  disabledSecondary={false}
                />
              </Link>
            </>
          ) : (
            <>
              <div className="flex w-full items-center gap-2">
                <SubframeCore.Icon
                  className="text-body font-body text-error-500"
                  name="FeatherAlertCircle"
                />
                <span className="font-['Inter'] text-[14px] font-[600] leading-[20px] text-neutral-900">
                  Unable to find appointment. <br /> Please check the link and
                  try again.
                </span>
              </div>
              <Link to={Path.HOME} className="w-full">
                <AsButton
                  className="h-14 w-full flex-none"
                  variant="secondary"
                  leadingIcon="FeatherCalendar"
                  trailingIcon={null}
                  text="Go to schedule"
                  size="lg"
                  loader={false}
                  disabled={false}
                  disabledSecondary={false}
                />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
